import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, filter, tap } from "rxjs/operators";
import { getFirstError } from "../../util/form.util";
import * as _ from 'lodash';
export class FormCheckerValidator{
    form: UntypedFormGroup;
    labels: any; //{field1:alias1, field2: alias2}
    fieldAlias!: string;
    validFcn: any;
    subscriptions: Subscription[] = [];
    error: string|null = null;
  
    constructor(form: FormGroup, options: any){
        this.form = form;
        this.labels = _.get(options, 'labels');
    
        this.subscriptions.push(
          
          this.form.valueChanges.pipe(
            // tap(()=>{
            //   console.log({dirty: this.form.dirty})
            // }),
            filter(()=>this.form.dirty==true),
            debounceTime(250)
          ).subscribe(()=>{
            this.forceCheck();
            // const error = this.validFcn(value);
            // this.error = undefined;
            // if(error!=undefined && error != null){
            //   this.error = error;
            // }
          })
        )
    }

    forceCheck(){
      this.error = getFirstError(this.form, {labels: this.labels});
    }

    clear(): void {
      this.subscriptions.forEach(sub=>sub.unsubscribe());
    }
  }