import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const ben_MENUITEMS = [
  {
    label: 'Gestión de Tarjetas',
    main: [{
      state: 'admvouchers',
      short_label: 't',
      name: 'Gestión de Tarjetas',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'admvouchers',
          name: 'Tarjetas'
        }
      ],
    }],
  },
  {
    label: 'Transacciones',
    main: [{
      state: 'purchases',
      short_label: 'e',
      name: 'Transacciones',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'purchases',
          name: 'Compras'
        }
      ],
    }],
  },
  {
    label: 'Buscador de Asociados',
    main: [{
      state: 'associateds',
      short_label: 'a',
      name: 'Buscador de Asociados',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'associateds',
          name: 'Asociados'
        }
      ],
    }],
  },
];

const comp_ent_MENUITEMS = [
  {
    label: 'Gestión de Tarjetas',
    main: [{
      state: 'admvouchers',
      short_label: 't',
      name: 'Gestión de Tarjetas',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'admvouchers',
          name: 'Tarjetas'
        }
      ],
    }],
  },
  {
    label: 'Reportes',
    main: [{
      state: 'reports',
      short_label: 'a',
      name: 'Reportes',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'purchases',
          name: 'Movimientos'
        }
      ],
    }],
  },
];

const ent_MENUITEMS = [
  {
    label: 'Modulo de Entidades',
    main: [{
      state: 'navigation',
      short_label: 'e',
      name: 'Modulo de Entidades',
      type: 'sub',
      icon: 'ti-layers color-3',
      children: [
        {
          state: 'companies',
          name: 'Organizaciones y Unidades de Compra'
        },
        {
          state: 'beneficiaries',
          name: 'Beneficiarios'
        },
      ],
    }],
  },
  {
    label: 'Gestión de Tarjetas',
    main: [{
      state: 'resources',
      short_label: 'G',
      name: 'Gestión de Tarjetas',
      type: 'sub',
      icon: 'ti-user',
      children: [
        // {
        //   state: 'assignation-voucher',
        //   name: 'Solicitud de tarjetas'
        // },
        {
          state: 'admvouchers',
          name: 'Tarjetas CLERTICKET'
        },
        {
          state: 'smart-vouchers',
          name: 'Tarjetas inteligentes'
        },
        {
          state: 'voucher-replacement',
          name: 'Estado reposición de tarjetas'
        },
      ],
    }],
  },
  {
    label: 'CLERTICKET SAP BTP',
    main: [{
      state: 'sap',
      short_label: 'X',
      name: 'CLERTICKET SAP BTP',
      type: 'sub',
      icon: 'ti-user',
      children: [
        {
          state: 'sap/entities',
          name: 'Entidades y Unidades de Compra'
        },
        {
          state: 'sap/contracts',
          name: 'Contratos'
        },
        {
          state: 'sap/exchanges',
          name: 'Canjes'
        },
        {
          state: 'sap/invoices',
          name: 'Facturas'
        },
      ],
    }],
  },
  {
    label: 'Reportes',
    main: [{
      state: 'management',
      short_label: 'M',
      name: 'Reportes',
      type: 'sub',
      icon: 'ti-settings',
      children: [
        {
          state: 'saleorders',
          name: 'Ordenes de Compra'
        },
        // {
        //   state: 'purchases',
        //   name: 'Compras'
        // },
        //{
        //  state: 'voucherreport',
        //  name: 'Saldos disponibles'
        //},
        {
          state: 'salesandstatus',
          name: 'Saldos disponibles'
        },
      ],
    }],
  }
];

const adm_MENUITEMS = [
  {
    label: 'Modulo de Entidades',
    main: [{
      state: 'navigation',
      short_label: 'e',
      name: 'Modulo de Entidades',
      type: 'sub',
      icon: 'ti-layers color-3',
      children: [
        {
          state: 'companies',
          name: 'Organizaciones y Unidades de Compra'
        },
        {
          state: 'beneficiaries',
          name: 'Beneficiarios'
        },
        {
          state: 'users',
          name: 'Usuarios'
        },
        {
          state: 'groups',
          name: 'Grupos'
        }
      ],
    }],
  },
  {
    label: 'Ventas',
    main: [{
      state: 'suit',
      short_label: 'V',
      name: 'Ventas',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'associateds',
          name: 'Asociados',
        },
        {
          state: 'associated-groups',
          name: 'Grupos asociados',
        },
        {
          state: 'contracts',
          name: 'Contratos'
        },
        {
          state: 'contract-templates',
          name: 'Borradores de contratos'
        },
        {
          state: 'saleorders',
          name: 'Ordenes de Compra'
        },
        {
          state: 'conciliation',
          name: 'Conciliación'
        },
        {
          state: 'provsales',
          name: 'Aprovisionamiento de Ventas'
        },
        {
          state: 'products',
          name: 'Productos Asociados'
        },
        {
          state: 'payments',
          name: 'Pronto Pagos'
        },
        {
          state: 'track-payments',
          name: 'Seguimiento de pagos'
        },
      ],
    }],
  },
  {
    label: 'Gestión de Tarjetas',
    main: [{
      state: 'resources',
      short_label: 'G',
      name: 'Gestión de Tarjetas',
      type: 'sub',
      icon: 'ti-user',
      children: [
        {
          state: 'admvouchers',
          name: 'Tarjetas CLERTICKET'
        },
        {
          state: 'assignation-voucher',
          name: 'Asignación de Tarjetas'
        },
        {
          state: 'voucher-replacement',
          name: 'Estado reposición de tarjetas'
        },
        {
          state: 'msr-cards',
          name: 'Tarjetas MSR'
        },
        {
          state: 'smart-vouchers',
          name: 'Tarjetas inteligentes'
        },
        {
          state: 'smart-voucher-assignations',
          name: 'Seguimiento de Asignación Tarjetas'
        },
      ],
    }],
  },
  {
    label: 'Monedero SMU',
    main: [{
      state: 'resources',
      short_label: 'M',
      name: 'Monedero SMU',
      type: 'sub',
      icon: 'ti-user',
      children: [
        {
          state: 'monedero',
          name: 'Monederos'
        },
        {
          state: 'campaign',
          name: 'Campañas'
        },
      ],
    }],
  },
  {
    label: 'CLERTICKET sap BTP',
    main: [{
      state: 'sap',
      short_label: 'X',
      name: 'CLERTICKET sap BTP',
      type: 'sub',
      icon: 'ti-user',
      children: [
        {
          state: 'sap/entities',
          name: 'Entidades y Unidades de Compra'
        },
        {
          state: 'sap/contracts',
          name: 'Contratos'
        },
        {
          state: 'sap/exchanges',
          name: 'Canjes'
        },
        {
          state: 'sap/invoices',
          name: 'Facturas'
        },
      ],
    }],
  },
  {
    label: 'Reportes',
    main: [{
      state: 'management',
      short_label: 'M',
      name: 'Reportes',
      type: 'sub',
      icon: 'ti-settings',
      children: [
        {
          state: 'purchases',
          name: 'Compras'
        },{
          state: 'reports-saleorder',
          name: 'Orden de compra'
        },{
          state: 'reports-voucher',
          name: 'Tarjeta'
        },{
          state: 'reports-issues',
          name: 'Problemas y asignaciones'
        },{
          state: 'reports-summary',
          name: 'Resumen'
        }
      ],
    }],
  },
  {
    label: 'Carga masiva',
    main: [{
      state: 'management',
      short_label: 'CM',
      name: 'Carga masiva',
      type: 'sub',
      icon: 'ti-user',
      children: [
        {
          state: 'batch-load-beneficiary',
          name: 'Beneficiarios'
        },
        {
          state: 'batch-load-users',
          name: 'Usuarios'
        },
        {
          state: 'batch-load-user-beneficiaries',
          name: 'Usuarios & Beneficiarios'
        },
        {
          state: 'batch-load-voucher',
          name: 'Tarjetas CLERTICKET'
        },
        {
          state: 'batch-load-recharge',
          name: 'Recarga Masiva'
        },
        {
          state: 'batch-load-smart-voucher',
          name: 'Tarjetas inteligentes'
        },
        {
          state: 'sales-batch-load',
          name: 'Reportes ventas'
        },
      ],
    }],
  }
];

const dev_MENUITEMS = [
  {
    label: 'Gestión de Tarjetas',
    main: [{
      state: 'resources',
      short_label: 'G',
      name: 'Gestión de Tarjetas',
      type: 'sub',
      icon: 'ti-user',
      children: [
        {
          state: 'admvouchers',
          name: 'Tarjetas CLERTICKET'
        },
        {
          state: 'smart-vouchers',
          name: 'Tarjetas inteligentes'
        }
      ],
    }],
  },
];

const dccp_MENUITEMS = [
  {
    label: 'Reportes',
    main: [{
      state: 'management',
      short_label: 'M',
      name: 'Reportes',
      type: 'sub',
      icon: 'ti-settings',
      children: [
        {
          state: 'dccp',
          name: 'DCCP'
        }
      ],
    }],
  }
];

const ch_compras_MENUITEMS = [
  {
    label: 'Reportes',
    main: [{
      state: 'management',
      short_label: 'M',
      name: 'Reportes',
      type: 'sub',
      icon: 'ti-settings',
      children: [
        {
          state: 'nourishmentreport',
          name: 'Subcategoría de alimentos'
        },
        {
          state: 'giftcardreport',
          name: 'Subcategoría de Tarjetas de beneficios'
        },
        {
          state: 'benefitsreport',
          name: 'Entrega de beneficios sociales'
        },
      ],
    }],
  }
];

const sales_MENUITEMS = [
  {
    label: 'Ventas',
    main: [{
      state: 'suit',
      short_label: 'V',
      name: 'Ventas',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'sales',
          name: 'Venta'
        }
      ],
    }],
  }
];

const con_sales_MENUITEMS = [
  {
    label: 'Inicio',
    main: [{
      state: 'sales',
      short_label: 'i',
      name: 'Inicio',
      type: 'sub',
      icon: 'ti-layers color-3',
      children: [
        {
          state: 'associateds',
          name: 'Asociados',
        },
        {
          state: 'contracts',
          name: 'Contratos',
        },
      ]
    }],
  },
  {
    label: 'Ventas',
    main: [{
      state: 'suit',
      short_label: 'V',
      name: 'Administración de ventas',
      type: 'sub',
      icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'conciliation',
          name: 'Conciliación'
        },
        {
          state: 'products',
          name: 'Productos'
        },
        {
          state: 'payments',
          name: 'Pronto Pagos'
        },
      ],
    }],
  }
];



@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    //console.log('getAll');
    return adm_MENUITEMS;
  }
  getEntity(): Menu[] {
    //console.log('getEntity');
    return ent_MENUITEMS;
  }
  getBeneficiary(): Menu[] {
    //console.log('getBeneficiary');
    return ben_MENUITEMS;
  }
  getPointofSales(): Menu[] {
    //console.log('getPointofSales');
    return sales_MENUITEMS;
  }
  getAssociatedCon(): Menu[] {
    //console.log('getAssociatedCon');
    return con_sales_MENUITEMS;
  }
  getAdquisitionsEntity(): Menu[] {
    //console.log('getAdquisitionsEntity');
    return comp_ent_MENUITEMS;
  }
  getDCPPEntity(): Menu[] {
    //console.log('getDCPPEntity');
    return dccp_MENUITEMS;
  }
  getCHEntity(): Menu[] {
    //console.log('getCHEntity');
    return ch_compras_MENUITEMS;
  }
  getDev(): Menu[] {
    return dev_MENUITEMS;
  }
}
