import { formatDate } from "./date.util";

export function formToFormData(form: FormGroup){
    const item = new FormData();
    for (let key of Object.keys(form.controls)) {
        // if(typeof form.controls[key].value ==='object'){ // es un archivo(?)

        //   item.append(key, form.controls[key].value, form.controls[key].value['name']);
        // }else{
        let value = form.controls[key].value;
        if(value == undefined || value == null) continue;
        console.log(`se añade ${key} con valor: `); console.dir(value);
        if(value instanceof Date){
            value = formatDate(value);
        }
        item.append(key, value);
        // }
    }
    return item;
}

import { AbstractControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach(key => {
    const control = controls[ key ];
    if(control instanceof UntypedFormControl || control instanceof UntypedFormGroup){
      if(!control.dirty){
        return;
      }
    }
    if (control instanceof UntypedFormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    // console.dir(control)
    const controlErrors: ValidationErrors = controls[ key ].errors as ValidationErrors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[ keyError ]
        });
      });
    }
  });
  return errors;
}

function genErrorText(errorName: string, field: string, value: any){
  console.log("get error text value: ", value)
  let text = "Error desconocido";
  switch (errorName) {
    case 'required': text = `El campo "${field}" es requerido!`; break;
    case 'pattern': text = `El campo "${field}" tiene un patrón erroneo!`; break;
    case 'email': text = `El campo "${field}" no respeta el formato de un email!`; break;
    case 'minlength': text = `El campo "${field}" tiene una cantidad de caracteres menor a la requerida! Se requieren al menos: ${value.requiredLength} caracteres`; break;
    case 'areEqual': text = `El campo "${field}" debe ser igual!`; break;
    case 'rut': text = `El campo "${field}" no es un rut válido`; break;
    case 'website': text = `El campo "${field}" no es un website válido`; break;
    case 'latlon': text = `El campo "${field}" no es una coordenada válida`; break;
    case 'min': text = `El campo "${field}" debe ser como mínimo ${value.min}. Valor ingresado: ${value.actual}`; break;
    case 'max': text = `El campo "${field}" debe ser como máximo ${value.max}. Valor ingresado: ${value.actual}`; break;
    default: text = `Campo "${field}": ${errorName}: ${value}`;
  }
  return text;
}

export function getFirstError(form: UntypedFormGroup, options: any = {}){
  let errorMessage = null;
  if (!form.valid) {
      const error: AllValidationErrors = getFormValidationErrors(form.controls).shift() as AllValidationErrors;
      if (error) {
        const fieldAlias = options['labels']?options['labels'][error.control_name] || error.control_name: error.control_name;
        console.log(`control name: ${error.control_name}`)
        console.log(`fieldAlias: ${fieldAlias}`);
        const text = genErrorText(error.error_name, fieldAlias, error.error_value);
        errorMessage = text;
      }
  }
  return errorMessage;
}

export function getErrorMap(form: UntypedFormGroup, options: any = {}){
  const errorMap: any = {};
  if (!form.valid) {
    const errors: AllValidationErrors[] = getFormValidationErrors(form.controls);
    for(let error of errors){
      if (error) {
        const fieldAlias = options['labels']?options['labels'][error.control_name] || error.control_name: error.control_name;
        console.log(`control name: ${error.control_name}`)
        console.log(`fieldAlias: ${fieldAlias}`);
        const text = genErrorText(error.error_name, fieldAlias, error.error_value);
        errorMap[error.control_name] = {
          text: text,
          alias: fieldAlias
        };
      }
    }
  }
  return errorMap;
}
