import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription ,  of } from 'rxjs';
import { HttpUtilsService, QueryParamsModel } from '../../../core/_base/crud';
import * as _ from 'lodash';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';

export interface BasicColumn{
  name: string; //nombre de la columna en BD, se usa para ordenar, debe ser referente a la tabla origen
  text: string; //nombre de la columna en la tabla, debe ser util al usuario
  transformFcn?(row: any):any; //Funcion que transforma la fila y retorna el valor que finalmente se muestra ( es opcional )
  isDate?: boolean; //En caso de ser true, el valor es transformado en texto fecha
  width?: number;
};

export interface BasicTableInterface{
  columnList: BasicColumn[];
  rowsObs: BehaviorSubject<any[]>;
  sortObs: BehaviorSubject<any>;
  totalCount: number;
  lastQuery?: QueryParamsModel;
  detailTemplate?: TemplateRef<any>;
  onDetailsExpansion?(row: any):void;
  getActionBtnList():any[];
  getRows(pageInfo: {page: number, pageSize: number}): any;
  fetchData(queryParams: QueryParamsModel): any;
  onSort(event: any): any;
  onClickActionBtn(event: any): any;
}

//Tabla de datos locales
export class BasicTable implements BasicTableInterface{
  columnList: BasicColumn[] = [];
  rowsObs: BehaviorSubject<any> = new BehaviorSubject(null);
  sortObs: BehaviorSubject<any> = new BehaviorSubject(null);
  totalCount: number = 0;
  lastQuery!: QueryParamsModel;
  detailTemplate!: TemplateRef<any>;
  data: any[] = [];
  shouldHideActionBtn: (btnText: string, row: any)=>boolean = (btnText: string, row: any) => false;

  constructor(columnList: BasicColumn[]){
    this.columnList = columnList;
  }

  setDetailTemplate(detailTemplate: TemplateRef<any>){
    this.detailTemplate = detailTemplate;
  }

  getActionBtnList(): any[] {
    return [];
  }
  getRows(pageInfo: { page: number; pageSize: number; }) {
    var queryParams = new QueryParamsModel(
      this.lastQuery ? this.lastQuery.filter : {},
      this.lastQuery?.sortOrder,
      this.lastQuery?.sortField,
      pageInfo.page - 1,
      pageInfo.pageSize,
      undefined
    );
    this.lastQuery = queryParams;
    this.fetchData(queryParams);
  }
  fetchData(queryParams: QueryParamsModel){
    let rows = this.data;
    if(queryParams.sortOrder && queryParams.sortField){
      rows = _.orderBy(rows, [queryParams.sortField], [queryParams.sortField.toLowerCase() as "asc" | "desc"]);
    }
    if(queryParams.pageSize != undefined && queryParams.pageNumber != undefined){
      const chunks = _.chunk(rows, queryParams.pageSize);
      rows = chunks[queryParams.pageNumber];
    }
    this.totalCount = _.get(this.data, 'length', 0);
    // this.cdr.detectChanges();
    this.rowsObs.next(rows);
  }
  refreshTable(){
    this.fetchData(this.lastQuery);
  }
  setData(rows: any[]){
    this.data = rows;
    this.refreshTable();
  }
  onSort(event: any) {
    //console.log("sort"); console.dir(event);
    const sort = event.sorts && event.sorts.length > 0 ? event.sorts[0] : undefined;
    if (sort != undefined) {
        this.sortObs.next({ sortOrder: sort.dir, sortField: sort.prop });
    }
  }
  //Eventos al presionar un boton de accion de alguna fila de la tabla
  onClickActionBtn(event: any) {
    //console.log("click action btn: "); console.dir(event);
    // this.clickActionBtnEvent.emit(event);
    const btn = event.button;
    const row = event.row;

    // if (this.enableCommonActions.delete && btn.text == this.deleteBtn.text) {
    //     this.selectedRow = row;
    //     this.deleteModal.display = true;
    // }else if (this.enableCommonActions.edit && btn.text == this.editBtn.text) {
    //     this.selectedRow = row;
    //     this.mode = "edit";
    //     this.formModal.title = this.formModal.title.replace('Nuevo', 'Modificar');
    //     this.formModal.display = true;
    // }
  }

  onDetailsExpansion(row: any){

  }
}

//Tabla con paginacion bd api
export class PageTable extends BasicTable{

  subscriptions: Subscription[] = [];
  getPage: (queryParams: QueryParamsModel) => Observable<{ totalCount: number; items: any[]; }>;
  dataObs: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(columnList: BasicColumn[], getPage: (queryParams: QueryParamsModel) => Observable<{totalCount: number, items: any[]}>){
    super(columnList);
    this.getPage = getPage;
  }

  listenEvents(){
    this.subscriptions.push(
      this.rowsObs.pipe(
          filter(rows=>rows && rows.length > 0),
          take(1),
          switchMap(()=>this.sortObs),
          filter(sortParams => sortParams != undefined),
          debounceTime(250)
      ).subscribe(sortParams => {
          this.lastQuery.sortField = sortParams.sortField;
          this.lastQuery.sortOrder = sortParams.sortOrder;
          this.fetchData(this.lastQuery);
      })
    );
  }

  override fetchData(queryParams: QueryParamsModel){
    this.lastQuery = queryParams;
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.getPage(queryParams))
      ).subscribe(resp=>{
        console.log("resp"); console.dir(resp);
        this.totalCount = resp.totalCount;
        // this.cdr.detectChanges();
        this.rowsObs.next(resp.items);
        this.dataObs.next(resp);
      })
    );
  }

  destroy(){
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }
}

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss',

  ]
})
export class BasicTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('myTable') table: any;
  isCheck: boolean = true;
  expanded: any = {};

  // @ViewChild(DatatableComponent) table: DatatableComponent;
  tablestyle = 'bootstrap';
  rows = [];
  fullScreenRow = [];
  loadingIndicator = true;
  reorderable = true;

  isLoading: boolean = false;
  page: any;

  private subscriptions: Subscription[] = [];

  @Input() columnList: BasicColumn[] = [];
  @Input() actionBtnList: any[] = [];
  @Input() rowsObs!: Subject <any[]>;
  @Input() limit: number = 10;
  @Input() totalCount: number = 1;
  @Input() shouldHideActionBtn = (btnText: string, row:any) => false;
  @Input() detailTemplate!: TemplateRef<any>;
  @Output() getRowsEvent = new EventEmitter<any>()
  @Output() expandEvent = new EventEmitter<any>()
  @Output() clickActionBtnEvent = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  pageList: number[] = [];
  currentPage: number = 1;
  lastPage:number = 1;

  constructor(
    private cdr: ChangeDetectorRef
  ) {

  }
  ngOnDestroy(): void {
      this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.subscriptions.push(
        this.rowsObs.subscribe((rows: any)=>{
          console.log("rows: ");console.dir(rows);
            this.rows = rows || [];
            for(let row of this.rows){
              row['buttonList'] = this.getNotHiddenActionBtnList(row)
            }
            this.refreshPagination();
            this.cdr.detectChanges();
            this.isLoading = false;
        })
    )
    this.requestItems(this.currentPage);
  }

  toggleExpandRow(row: any) {
    console.log('Toggled Expand Row!', row);
    const expanded = _.get(row, 'expanded', false);
    if(!expanded) this.expandEvent.emit(row);
    row['expanded'] = !expanded;
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event: any) {
   console.log('Detail Toggled', event);
  }

  getNotHiddenActionBtnList(row: any){
    if(this.shouldHideActionBtn==null || this.shouldHideActionBtn == undefined){
      return this.actionBtnList;
    }

    const actionBtnList = this.actionBtnList.filter(btn=>!this.shouldHideActionBtn(btn.text, row));
    const _actionBtnList = [];
    for(let btn of actionBtnList){
      const _btn = _.cloneDeep(btn);
      if(_btn.children){
        _btn.children = _btn.children.filter(child=>!this.shouldHideActionBtn(child.text, row));
      }
      _actionBtnList.push(_btn);
    }

    console.log(`Action btn list for ${row.file_name}`, _actionBtnList)
    return _actionBtnList;
  }

  refreshPagination(){
    this.pageList = [];
    let startPage = this.currentPage-3;
    if(startPage <= 0) startPage = 1;
    let endPage = startPage + 6;
    if(endPage > (this.totalCount || 0)/this.limit) endPage = Math.ceil((this.totalCount||0)/this.limit)

    console.log(`start page: ${startPage} | end page: ${endPage} | total: ${this.totalCount} | limit: ${this.limit}`);
    for(let i = startPage;i<=endPage;i+=1){
      this.pageList.push(i);
    }

    this.lastPage = Math.ceil(this.totalCount/this.limit);
  }

  requestItems(selectedPage: any){
    this.isLoading = true;
    this.clearTable();
    const request = {page: selectedPage, pageSize: this.limit};
    console.log("request: ");console.dir(request);
    this.getRowsEvent.emit(request);
    this.currentPage = selectedPage;
    this.refreshPagination();
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  // setPage(pageInfo) {
  //   this.loadItems(pageInfo);
  // }

  onClickActionBtn(row: any, button: any){
    this.clickActionBtnEvent.emit({
      row: row,
      button: button
    });
  }

  clickPaginationBtn(selectedPage: string|number){
    console.log('pagination button clicked');console.dir(selectedPage);
    if(selectedPage == 'next'){
      this.requestItems(this.currentPage+1);
    }else if(selectedPage == 'prev'){
      this.requestItems(this.currentPage-1);
    }else{
      this.requestItems(selectedPage);
    }
  }

  sortEvent(event: any){
    this.isLoading = true;
    this.clearTable();
    this.onSort.emit(event);
  }

  clearTable(){
    this.rows = [];
    this.cdr.detectChanges();
  }
}
