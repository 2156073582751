import {environment} from "../../environments/environment";

export const BASE_URL = environment.base_url;              //INTERNAL ACCESS
// export const BASE_URL = "http://192.168.2.22:3005"   //IR4HUB DEVELOPMENT SERVER
// export const BASE_URL = "http://192.168.2.19:9003"   //IR4HUB DEVELOPMENT SERVER
// export const BASE_URL = "http://192.168.2.15:3000"   //IR4HUB DEVELOPMENT SERVER
//export const BASE_URL = "https://plataforma.clerticket.cl:45174"   //IR4HUB WEB PROD SERVER
//export const BASE_URL = "https://lab.clerticket.cl:45117"   //IR4HUB DEVELOPMENT SERVER clerticket-node3
//export const BASE_URL = "http://34.16.22.131:3001"   //IR4HUB DEVELOPMENT SERVER clerticket-node3
//export const BASE_URL = "https://pdi.clerticket.cl:45358"   //WEB PDI
//export const BASE_URL = "https://pdi.clerticket.cl:45061"  //APK PDI
export const PROMETHEUS_BASE_URL = environment.base_url;
//export const PROMETHEUS_API_RELATIVE = "/prometheus/api/v1";

//export const PROMETHEUS_BASE_URL = "https://lab.clerticket.cl:45050";
//export const PROMETHEUS_BASE_URL = "https://plataforma.clerticket.cl:45174";
export const PROMETHEUS_API_RELATIVE = "/prometheus/api/v1";

export const API_USER_URL = BASE_URL + `/users`
export const API_USERS_V2_URL = BASE_URL + `/users/v2`
export const API_GROUP_URL = BASE_URL + `/group`
export const API_USER_BENEFICIARY_BATCH_LOAD_URL = BASE_URL + `/users-beneficiaries/batch-load`
export const API_ADQUISITION_UNIT_URL = BASE_URL + `/adquisition-unit`
export const API_BENEFICIARY_URL = BASE_URL + `/beneficiary`
export const API_ENTITY_URL = BASE_URL + `/entity`
export const API_USER_BATCH_LOAD_URL = BASE_URL + `/users/batch-load`
export const API_VOUCHER_PROVIDER_URL = BASE_URL + `/voucher/provider`
export const API_VOUCHER_TYPE_URL = BASE_URL + `/voucher/type`
export const API_VOUCHER_STATE_URL = BASE_URL + `/voucher/state`
export const API_VOUCHER_URL = BASE_URL + `/voucher`
export const API_CONTRACTS_URL = BASE_URL+'/contracts';
export const API_ASSOCIATED_URL = BASE_URL+'/associatedv2';
export const API_PAYMENT_URL = BASE_URL+'/payment';
export const API_CONTRACT_TEMPLATE_URL = BASE_URL+'/contract-template';
export const API_CONTRACT_STATUS_URL = BASE_URL+'/contract-status';
export const API_SMART_VOUCHER_ASSIGNATION = BASE_URL+'/smart';
export const API_SMART_VOUCHER_TYPE = BASE_URL+'/smart/type';
export const API_SMART_BATCH_LOAD = BASE_URL+'/smart/batch-load';
export const API_RECHARGE_BATCH_LOAD = BASE_URL+'/recharge/batch-load';
export const API_SMART_VOUCHER = BASE_URL+'/smart-voucher';
export const API_BATCH_LOAD_STATUS = BASE_URL+'/batchload/status';
export const API_VOUCHER_BATCH_LOAD = BASE_URL+'/voucher/batch-load';
export const API_VOUCHER_BATCH_LOAD_REPORT_DETAIL = BASE_URL+'/voucher/batch-load/report/detail';
export const API_SALE_ORDERV2 = BASE_URL+'/sale-orderv2';
export const API_BARCODE_URL = BASE_URL+'/barcode';
export const API_REGION_URL = BASE_URL+'/location/region';
export const API_CITY_URL = BASE_URL+'/location/city';
export const API_COMMUNE_URL = BASE_URL+'/location/commune';
export const API_SMU_MONEDERO_URL = BASE_URL+'/smu/monedero';
export const API_ASSOCIATED_TRANSACTION_PRODUCT_URL = BASE_URL+'/associated/transaction/product';
export const API_SALES_BATCH_LOAD = BASE_URL+'/sales-batch-load';
export const API_VOUCHER_TRANSACTION = BASE_URL+'/voucher/transaction';
export const API_SMU_CAMPAIGN_URL = BASE_URL+'/smu/campaign';
export const API_PARTNER_URL = BASE_URL+'/partner';
export const API_ENTITY_TYPE_URL = BASE_URL+'/entity/type';
export const API_SAP_CONTRACT_URL = BASE_URL+'/sap/contract';
export const API_SAP_EXCHANGE_URL = BASE_URL+'/sap/exchange';
export const API_SAP_INVOICE_URL = BASE_URL+'/sap/invoice';
export const API_SAP_INVOICE_SALES_URL = BASE_URL+'/sap/invoice/sales';
export const API_SAP_CURRENCY_URL = BASE_URL+'/sap/currency';
export const API_SAP_CURRENCY_RATE_URL = BASE_URL+'/sap/currency/rate';
export const API_PRODUCT_URL = BASE_URL+'/product';
export const API_PRODUCT_CATEGORY_URL = BASE_URL+'/product/category';
export const API_STATUS_URL = BASE_URL+'/status';
